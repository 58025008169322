(function (app) {
    'use strict';

    /*
    |--------------------------------------------------------------------------
    | DO NOT EDIT THIS FILE!
    |--------------------------------------------------------------------------
    */

    var _radioWindow;
    var _ref = 'radio'; // window reference, to open always in the same window
    var _size = 'height=550,width=400';

    app.radio = {
        start: function (href, name) {
            if (!_radioWindow || _radioWindow.closed) {
                _radioWindow = window.open('', _ref, _size);

                var timer = setInterval(function () {
                    if (_radioWindow.closed) {
                        clearInterval(timer);
                        app.radio.stop();
                    }
                }, 800);

            } else {
                _radioWindow.focus();
            }

            if (_radioWindow && _radioWindow.location.href !== href) {
                _radioWindow.location.href = href;
            }
        },
        stop: function () {
            $('.list-radio .item-radio').removeClass('active');
        }
    }

})(App);