(function (app) {
    'use strict';

    var module = {};

    module.menu = (function () {
        // Init menu
        var $menu = $('.header');
        var $hamburger = $('.hamburger');

        $hamburger.on('click', function (e) {
            $hamburger.toggleClass('is-active');

            if ($menu.hasClass('is-open')) {
                $('.header-nav').hide(0, function () {
                    $menu.removeClass('is-open');
                    $('body').css('overflow', 'auto');
                });
            } else {
                $('.header-nav').show(0, function () {
                    $menu.addClass('is-open');
                    $('body').css('overflow', 'hidden');
                });
            }
        });

        return {
            open: function () {

            },
            close: function () {

            }
        };
    })();

    // Livestream
    $('[data-livestream]').click(function (e) {
        e.preventDefault();

        var $channel = $(this);
        //var channelId = $channel.find('.btn-play-channel').data('channel-id');

        app.radio.start($channel.find('a').attr('href'));
        app.currentSong.refresh();
    });

    // Set width of streamplayer
    $('iframe.stream').each(function () {
        var $iframe = $(this);
        var src = $(this).data('src');
        var playerHeight = '40px';

        $iframe.attr('src', src + '&h=' + playerHeight + '&w=' + Math.round($iframe.parent().width()) + 'px&bg=000000&autostart=false');
    });

    app.ui = module;

}(App));
