(function (app) {
    'use strict';

    var $currently = $('.js-currently');

    if ($currently.length === 0) {
        return;
    }

    var fails = 0;
    var list = [];
    var currentSongs = [];
    var songsInitiated = false;
    var timeDiff = 0;

    var getNow = function () {
        return (new Date).getTime() / 1000 - timeDiff;
    };

    var determineNextSong = function ($wrap, indexCurrentSong, radioId) {
        var $nextSongWrap = $wrap.siblings('.js-next-song-wrap');

        if ($nextSongWrap.length) {
            var nextSong = list[radioId][indexCurrentSong + 1] || false;

            if (nextSong) {
                $nextSongWrap.fadeIn().find('.js-only-songtitle').text(nextSong.title);
                $nextSongWrap.find('.js-only-artist').text(nextSong.artist ? nextSong.artist : '');

                return;
            }
        }

        // Hide when empty
        $nextSongWrap.fadeOut();
    };

    var setCurrentSongs = function () {
        for (var radioId in list) {
            if (!list.hasOwnProperty(radioId)) {
                continue;
            }
            var $radio = $currently.filter(function () {
                return $(this).data('id') == radioId;
            });

            if ($radio.length === 0) {
                continue;
            }

            $.each(list[radioId], function (i, song) {
                if (song.ends_at < getNow()) {
                    return;
                }

                if ($radio.parent('.js-currently-wrap').length) {
                    var $wrap = $radio.parent('.js-currently-wrap');
                    $wrap.find('.js-only-songtitle').text(song.title);
                    $wrap.find('.js-only-artist').text(song.artist || '');

                    determineNextSong($wrap, i, radioId);
                } else {
                    $radio.text(song.title + (song.artist ? ' – ' + song.artist : ''));
                }

                // Save current song to use it later to schedule song switch
                currentSongs[radioId] = song;

                return false;
            });
        }

        songsInitiated = true;
    };

    var setNewList = function (newList) {
        list = newList;
    };

    var setDifferenceWithLocalTime = function (serverTime) {
        timeDiff = getNow() - serverTime;
    };

    var scheduleNextSongs = function () {
        var time = Number.MAX_VALUE;
        var fetchNext = false;

        // Find next song change for any radio
        for (var radioId in list) {
            if (!list.hasOwnProperty(radioId) || !currentSongs[radioId]) {
                continue;
            }

            var checkNextSong = false;

            // Check if we have a next song for this radio
            $.each(list[radioId], function (_, song) {
                if (currentSongs[radioId].ends_at === song.ends_at) {
                    checkNextSong = true;

                    if (time > song.ends_at) {
                        time = song.ends_at + 1; // Add one second to be sure next is started
                    }
                } else if (checkNextSong) {
                    checkNextSong = false;
                }
            });

            // Only if we dont have a next song this variable is true
            if (checkNextSong) {
                fetchNext = true;
            }
        }

        if (fetchNext || time === Number.MAX_VALUE) {
            fetchNextList();
        }

        if (time !== Number.MAX_VALUE) {
            var schedule = time - getNow(); // seconds

            setTimeout(function () {
                setCurrentSongs();
                scheduleNextSongs();
            }, schedule * 1000);
        } else {
            songsInitiated = false;
        }
    };

    var lastFetch;

    var fetchNextList = function () {
        if (lastFetch && getNow() - lastFetch < 15) {
            console.error('Prevent too many requests.');

            scheduleFetchNextList(15);
            return;
        }

        lastFetch = getNow();

        var requestStart, requestDuration; // seconds

        $.ajax({
            url: '/api/nummers',
            dataType: 'json',
            beforeSend: function () {
                requestStart = +new Date / 1000;
            },
            success: function (json) {
                requestDuration = (+new Date / 1000) - requestStart;

                setNewList(json.list);

                if (!songsInitiated) {
                    // Start the cascade
                    setCurrentSongs();
                    scheduleNextSongs();
                }

                fails = 0;
            },
            complete: function (jqXhr) {
                // To detect if the response comes from browser cache, we measure the request duration
                // If it is too quick, we assume we have got a cached response
                if (requestDuration < 0.05) {
                    return;
                }

                var dateString = jqXhr.getResponseHeader('Date');

                if (dateString) {
                    if (dateString.indexOf('GMT') === -1) {
                        dateString += ' GMT';
                    }
                    var serverTime = (new Date(dateString)).getTime() / 1000;

                    setDifferenceWithLocalTime(serverTime + 0.15);
                }
            },
            error: function () {
                scheduleFetchNextList(20 + (fails * 20));

                fails++;
            }
        })
    };

    var scheduleFetchNextList = function (seconds) {
        setTimeout(fetchNextList, seconds * 1000);
    };

    setTimeout(fetchNextList, 300);

    app.currentSong = {
        refresh: function () {
            setCurrentSongs();
        }
    }

}(App));