(function (app) {
    'use strict';

    if (!app.request.is('special-home')) {
        return;
    }

    var $channels = $('.channel');

    $channels.find('.wb-info').hover(
        function () {
            $(this).closest('.channel').find('.channel-overlay').addClass('active');
        }, function () {
            $(this).closest('.channel').find('.channel-overlay').removeClass('active');
        }
    );

    $('.js-dagtekst').on('click', function () {
        $(this).toggleClass('active');
    });
}(App));
