(function (app) {
    'use strict';

    /*
    |--------------------------------------------------------------------------
    | DO NOT EDIT THIS FILE!
    |--------------------------------------------------------------------------
    */

    var cookieName = 'cookies-accepted';

    if (Cookies.get(cookieName) !== '1') {
        $.get('/cookiebar', function (html) {
            $('body').prepend(html);

            var $cookieBar = $('.cookiebar');

            $cookieBar.find('.close').click(function (e) {
                e.preventDefault();

                Cookies.set(cookieName, '1', {expires: 365});

                $cookieBar.animate({
                    bottom: -1 * $cookieBar.outerHeight() - 15
                }, function () {
                    $cookieBar.remove();
                });
            });
        });
    }

})(App);