(function (app) {
    'use strict';

    /*
    |--------------------------------------------------------------------------
    | DO NOT EDIT THIS FILE!
    |--------------------------------------------------------------------------
    */

    var _setLineClamp = function ($parent) {
        var $clamp;

        if ($parent) {
            $clamp = $parent.is('.row') ? $parent : $parent.find('.row:has([data-lineclamp])');
        } else {
            $clamp = $('.row:has([data-lineclamp])');
        }

        $clamp.each(function () {
            if (app.isMobile) {
                return;
            }

            var isInteger = function (num) {
                return (num ^ 0) === num;
            };

            var $row = $(this);
            var height = {};

            $row.find('[data-lineclamp]').each(function () {
                var tag = this.tagName;

                if (!isInteger(height[tag])) {
                    height[tag] = 0;
                }

                var itemHeight = $(this).outerHeight();
                if (height[tag] < itemHeight) {
                    height[tag] = itemHeight;
                }
            });

            for (var tag in height) {
                if (!height.hasOwnProperty(tag)) {
                    continue;
                }

                $row.find(tag + '[data-lineclamp]').css('minHeight', height[tag]);
            }
        });
    };


    app.lineclamp = (function () {
        _setLineClamp();

        return _setLineClamp;
    })();

})(App);